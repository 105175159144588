@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
.App {
  text-align: center;
  margin:0;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Landing {
  width: 100%; /* Full viewport height */
}
.menu-icon {
  display: none; /* Hide the menu icon by default */
}

.navbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 110%;
  background-color: #ffffff; /* White background */
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: right 0.3s ease;
  z-index: 999; /* Ensure navbar is on top */
}

.navbar.open {
  right: 0;
}

.nav-links {
  display: flex;
  gap: 10px;
}

.nav-button,
.contact-button {
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.nav-button:hover,
.contact-button:hover {
  background-color: #f0f0f0;
}

.nav-logo {
  font-size: 24px;
}

.content {
  margin-top: 60px; /* Adjust according to navbar height */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Media query for small screens (up to 600px) */

@media screen and (max-width: 767px) {
  .menu-icon {
    display: block;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
    cursor: pointer;
  }
  
  .menu-icon .menu-line {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
    transition: transform 0.3s ease;
  }
  
  .menu-icon.open .menu-line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .menu-icon.open .menu-line:nth-child(2) {
    opacity: 0;
  }
  
  .menu-icon.open .menu-line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  .navbar {
    display: none; /* Hide the navbar on mobile screens by default */
    flex-direction: column;
    align-items: center;
  }

  .navbar.open {
    display: flex; /* Show the navbar when the menu is open */
  }

  .nav-links {
    flex-direction: column; /* Stack navigation links vertically on mobile screens */
  }

  .nav-logo {
    order: -1; /* Move the logo to the beginning */
    margin-bottom: 10px; /* Add margin to separate from the links */
  }
}

/* App.css */

.article-section {
  padding: 20px;
}

.article-section h2 {
  text-align: center;
}

.articles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px;
}

.article {
  width: 300px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin:5px;
}

.article img {
  width: 100%;
  height: 220px;
  border-radius: 5px;
}

.article-info {
  margin-top: 10px;
}

.article-info h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.article-info p {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.article-info a {
  display: inline-block;
  color: blue;
  text-decoration: none;
  font-weight: bold;
}

.article-info a:hover {
  text-decoration: underline;
}

/* App.css */

.contact-form {
  width: 95%;
  max-width: 600px; /* Set maximum width */
  margin: 0 auto; /* Center the form horizontally */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-group textarea {
  resize: vertical;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Elevation */
  padding: 20px;
}

.icon {
  font-size: 48px;
}

.text {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}


.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.json-input {
  margin: 10px 0;
}

.json-submit {
  margin: 10px 0;
}

.test-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 20px;
}

.passage {
  font-weight: bold;
  margin-bottom: 20px;
}

.options {
  list-style-type: none;
  padding-left: 0;
}

.options li {
  margin-bottom: 10px;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.correct-answer {
  color: green;
}



.feedback {
  margin-top: 10px;
}

.explanation {
  color: blue;
}

.tip {
  color: red;
}

.explanation-text,
.tip-text {
  font-style: italic;
}

.word-count {
  margin-bottom: 10px;
}

.category {
  font-weight: bold;
  margin-top: 5px;
  color: orange;
}

.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  max-width: 200px; /* Adjust according to your logo size */
}

.privacy-policy-content {
  text-align: justify;
}

.privacy-policy-content h1 {
  margin-bottom: 10px;
}

.privacy-policy-content p {
  margin-bottom: 20px;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  padding:0;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.column {
  padding: 20px;
  box-sizing: border-box;
}

.column-8 {
  width: 75%; /* 8/12 ratio */
  background-color: #fff;
  overflow-y: auto;
  scrollbar-width: none;
  scrollbar-color: white; /* Optional: Just to distinguish the columns */
}

.column-4 {
  width: 25%; /* 4/12 ratio */
  background-color: #fff; 
  overflow-y: auto;
  scrollbar-width: none;/* Optional: Just to distinguish the columns */
}
