
  
  @media only screen and (max-width: 600px) {
    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        width: auto;
        height: 400px;
        margin-bottom: 20px;
    border-radius: 16px;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(3.2px);
    -webkit-backdrop-filter: blur(3.2px);
    border: 0px solid rgba(255, 255, 255, 0.3);
        height: fit-content;
        padding: 0 2rem 2rem 2rem;
        border-radius: 10px;
        border-width: 0px;
        backdrop-filter: blur(10px);
      }
  } 


  @media only screen and (min-width: 601px) {
    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        width: "auto";
        height: 700px;
    border-radius: 16px;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(3.2px);
    -webkit-backdrop-filter: blur(3.2px);
    border: 0px solid rgba(255, 255, 255, 0.3);
        padding: 0 2rem 2rem 2rem;
        border-radius: 10px;
        backdrop-filter: blur(10px);
        border-width: 0px;
      }
  } 

  @media only screen and (max-width: 600px) {
    .card img {
        margin-top: 3%;
        width: 250px;
        padding:5px;
        border-radius: 10px;
        background-color: transparent;
        object-fit: fill;
        height: 500px;

      }
  } 
  
  @media only screen and (min-width: 601px) {
    .card img {
        margin-top: 5%;
        width: auto;
        padding:5px;
        border-radius: 10px;
        background-color: transparent;
        object-fit: fill;
        height: 500px;
      }
  } 


  @media only screen and (max-width: 600px) {
    .card h2 {
        margin: 0;
        margin-top: 1rem;
        color:black;
        font-weight: bold;
        margin-bottom: 0px;
        width: 100%;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        width: 300px;
        text-align: center;
        font-family:'Times New Roman', Times, serif
      }
  } 
  

  @media only screen and (min-width: 601px) {
    .card h2 {
        margin: 0;
        margin-top: 1rem;
        color:black;
        margin-bottom: 0px;
        width: 100%;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        text-align: center;
        width: 290px;
        font-family:'Times New Roman', Times, serif
      }
  } 
  
  
  @media only screen and (max-width: 600px) {
    .card p {
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 0rem;
        color: black;
        font-size: 12px;
        width: 300px;
        text-align: center;
        font-family: 'Times New Roman', Times, serif;
      }
      
  } 
  
  @media only screen and (min-width: 601px) {
    .card p {
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 0rem;
        color: black;
        width: 250px;
        text-align: center;
        font-family: 'Times New Roman', Times, serif;
      }
      
  } 
  
 
  .card .btnn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  