/* Login.css */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-container {
    background-color: white;
    padding: 4rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .logo {
    max-width: 200px;
    height: auto;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-label {
    font-weight: 600;
  }
  
  .form-control {
    border-radius: 4px;
  }
  
  .submit-button {
    width: 100%;
    margin-top: 1rem;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    background-color: #7f388b;
  }
  
  .alert {
    margin-bottom: 1rem;
  }
  
  .spinner-border {
    margin-right: 0.5rem;
  }