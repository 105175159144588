.profile-card {
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .profile-card:hover {
    transform: translateY(-5px);
  }
  
  .profile-image img {
    width: 100%;
    height: auto;
  }
  
  .profile-info {
    padding: 15px;
    text-align: center;
  }
  
  .profile-info h2 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
  }
  
  .profile-info h4 {
    margin: 5px 0;
    font-size: 1.1em;
    color: #777;
  }
  
  .profile-info p {
    margin: 10px 0 0;
    color: #555;
  }
  